.custom-menu {
  display: flex;
  flex-direction: column;
  width: fit-content;
  background-color: white !important;
}
.select-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
