.account-btn {
  box-shadow: none !important;
  padding: auto !important;
  height: 55px !important;
}

.name-span {
  margin-left: 5px;
  font-size: 14px;
  text-transform: capitalize;
}
