.layout-content {
  position: relative;
  overflow: hidden;
}

.cardbody {
  height: calc(100vh - 200px); /* Adjust height as needed */
  overflow: hidden;
}

.table-responsive {
  max-height: 42.3rem; /* Adjust height as needed */
  overflow-y: auto;
}

.table-responsive table {
  width: 100%;
  border-collapse: collapse;
}

.table-responsive thead th {
  position: sticky;
  top: 0;
  background: #fff; /* Background color to distinguish header */
  z-index: 1; /* Ensure header stays above the body */
}

.button-container {
  margin-right: 1rem;
  margin-bottom: 2rem;
  display: flex;
  font-weight: bold;
  margin-left: auto;
}

.sync-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
